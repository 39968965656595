import { NameAndCountry } from '#app/components/NameAndCountry.tsx';
import { getCountryFullName } from '#app/lib/countries.ts';
import { type User } from '#app/lib/firebase/models/user.ts';

export type WomanProps = {
  user: User;
  className?: string;
};

export const Woman = ({ user, className }: WomanProps) => {
  return (
    <div className={`static-woman-container ${className ? className : ''}`}>
      <div className="static-woman">
        <svg viewBox="0 0 380 380" fill="none" xmlns="http://www.w3.org/2000/svg">
          <title>{`${user?.name} · ${getCountryFullName(user?.country)}`}</title>
          <defs>
            <path
              id="woman-arc-down"
              d="M37 190.5a152.496 152.496 0 0 0 94.141 140.892 152.512 152.512 0 0 0 116.718 0 152.506 152.506 0 0 0 82.533-82.533A152.512 152.512 0 0 0 342 190.5H37Z"
              fill="#000"
            />
            <path
              id="woman-arc-up"
              d="M81.666 298.334a152.504 152.504 0 0 1-33.058-166.193A152.496 152.496 0 0 1 189.5 38a152.497 152.497 0 0 1 140.892 94.141 152.503 152.503 0 0 1-33.058 166.193L189.5 190.5 81.666 298.334Z"
              fill="#000"
            />
            <pattern id={`pattern-${user?.counter}`} patternContentUnits="objectBoundingBox" width="1" height="1">
              <use xlinkHref={`#image-${user?.counter}`} transform="translate(0 -.25) scale(.00246)" />
            </pattern>
            <image id={`image-${user?.counter}`} width="407" height="610" href={user?.pictureSrc} />
          </defs>

          <g>
            {/*Circle filled with the image*/}
            <circle cx="188.5" cy="190.5" r="152.5" fill={`url(#pattern-${user?.counter})`} />
          </g>

          <text dy="-16" textAnchor="middle" width="380" fontFamily="Metropolis" fontWeight="bold" fill="#fff" fontSize="32">
            <textPath startOffset="35%" xlinkHref="#woman-arc-up">
              <NameAndCountry name={String(user?.name)} country={getCountryFullName(user?.country)} />
            </textPath>
          </text>

          <text className="counter" dy="35" textAnchor="middle" width="380" fontFamily="Metropolis" fontWeight="400" fill="#fff">
            <textPath startOffset="30%" xlinkHref="#woman-arc-down">
              {String(user?.counter).padStart(6, '0')}
            </textPath>
          </text>
        </svg>
      </div>
    </div>
  );
};
