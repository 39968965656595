export function isSpaceInternet(spaceName: string) {
  return spaceName === 'internet' || spaceName === 'localhost';
}

export function truncateText(text?: string, maxLength?: number) {
  if (!text || !maxLength) {
    return '';
  }

  if (text.length > maxLength) {
    return `${text.substring(0, maxLength - 1)}...`;
  }

  return text;
}

export function toTitleCase(text: string) {
  const t = text.split(' ');
  return t
    .map(split => {
      if (split === '') {
        return '';
      }

      return split[0].toUpperCase() + split.substring(1).toLowerCase();
    })
    .join(' ');
}
