import { toTitleCase } from '#app/lib/helpers.ts';

export type NameAndCountryProps = {
  name: string;
  country: string;
};

export const NameAndCountry = ({ name, country }: NameAndCountryProps): JSX.Element => {
  if (!name || !country) {
    return <></>;
  }

  const text = `${name} 🌍 ${country}`;
  if (text.length > 47) {
    const max = 43 - name.length;

    return (
      <>
        <tspan>{toTitleCase(name)}</tspan>
        <tspan className="world" dy="-4">
          {' '}
          🌍{' '}
        </tspan>
        <tspan dy="4">{country.substring(0, max)}</tspan>
        ...
      </>
    );
  }

  return (
    <>
      <tspan>{toTitleCase(name)}</tspan>
      <tspan className="world" dy="-4">
        {' '}
        🌍{' '}
      </tspan>
      <tspan dy="4">{country}</tspan>
    </>
  );
};
